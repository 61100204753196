import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import { getColor } from '../../config/helpers/Helper'

export default function Email(props) {
    return (
        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>What's the best email to reach you on?</h2>
                {props.site === 'iyi' &&
                    <p className='question-subtext'>This is where we will send your quotes.</p>
                }
                {(props.site === 'solarfinder' || props.site === 'energyefficiency') &&
                    <p className='question-subtext'>This is where we will send your solar quotes, as well as relevant grant application and other useful information.</p>
                }
            </Grid>
            <Grid xs={12} sm={12}>
                <TextField 
                    color={getColor(props.site)} 
                    variant='outlined' 
                    type='email'
                    value={props.email} 
                    placeholder='name@example.com'
                    size='large'
                    onChange={(e) => props.setEmail(e.target.value)} 
                    style={{width: '100%'}}
                    inputProps = {
                        {
                            sx: {
                                '&::placeholder': {
                                    color: 'rgb(121, 118, 118)',
                                    opacity: 1
                                },
                            },
                        }
                    }
                />
            </Grid>
            {props.hasError &&
                <p className='error-text' style={{marginTop: '1rem'}}>Please enter a valid email address</p>
            }  
        </Grid>
    )
}