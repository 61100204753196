export function getColor(site) {
    if (site === 'energyefficiency') {
        return 'success'
    }
    else if (site === 'solarfinder') {
        return 'warning'
    }
    else if (site === 'iyi') {
        return 'warning'
    }
    else {
        return 'success'
    }
}

export function getPrivacyPolicy(site) {
    if (site === 'energyefficiency') {
        return 'https://energyefficiency.ie/privacy-policy'
    }
    else if (site === 'solarfinder') {
        return 'https://solarfinder.ie/privacy-policy'
    }
    else if (site === 'iyi') {
        return 'https://insureyourincome.ie/privacy-policy'
    }
}