import React, { useState, useRef } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import MostImportant from './Questions/MostImportant'
import HowMuch from './Questions/HowMuch'
import Timeline from './Questions/Timeline'
import DiverterOrBattery from './Questions/DiverterOrBattery'
import ElectricBills from './Questions/ElectricBills'
import Stories from './Questions/Stories'
import RoofType from './Questions/RoofType'
import GrantInterest from './Questions/GrantInterest'
import Eircode from './Questions/Eircode'
import Name from './Questions/Name'
import Email from './Questions/Email'
import Phone from './Questions/Phone'
import ConfirmPhone from './Questions/ConfirmPhone'
import PrivacyPolicy from './Questions/PrivacyPolicy'
import Invalid from './Invalid'
import Calculating from './Calculating'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Loader from './Loader'
import * as api from '../config/api/GuestAPI'
import { eircodesToCounty, validEmail } from '../config/constants/Constants'

const wait = t => new Promise((resolve, reject) => setTimeout(resolve, t))

export default function DataCaptureForm(props) {
    const [stage, setStage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [invalid, setInvalid] = useState()
    const [invalidReason, setInvalidReason] = useState('')
    const [redirectUrl, setRedirectUrl] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    const [codeError, setCodeError] = useState(false)
    const [eircodeError, setEircodeError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    // solar details
    const [mostImportant, setMostImportant] = useState('')
    //const [howMuch, setHowMuch] = useState('')
    const [timeline, setTimeline] = useState('')
    const [diverterOrBattery, setDiverterOrBattery] = useState('')
    const [electricBills, setElectricBills] = useState('')
    const [stories, setStories] = useState('')
    const [roofType, setRoofType] = useState('')
    const [hasGrantInterest, setHasGrantInterest] = useState('YES')
    // personal details
    const [eircode, setEircode] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    //const [confirmationCode, setConfirmationCode] = useState('')
    const [privacyPolicy, setPrivacyPolicy] = useState('')

    const dcForm = useRef(null)

    async function submitForm(e) {
        setLoading(true)
        if (e) {
            e.preventDefault()
        }
        let howMuch = ''
        if (electricBills === 'Under €1,200 / year') {
            howMuch = 'Small (Under 3kW)'
        }
        else if (electricBills === '€1,200 - €2,000 / year') {
            howMuch = 'Medium (3kW - 5kW)'
        }
        else if (electricBills === 'Over €2,000 / year') {
            howMuch = 'Large (Over 5kW)'
        }
        const details = {
            mostImportant,
            howMuch,
            timeline,
            diverterOrBattery,
            electricBills,
            stories,
            roofType,
            hasGrantInterest,
            eircode,
            firstName,
            lastName,
            email,
            phone,
            privacyPolicy: true
        }
        console.log('submit details: ', details)
        
        try {
            console.log('hitting add lead api...')
            let response = await api.submitSolarfinderQuotesForm(details)
            console.log('repsonse: ', response)

            // add delay
            await wait(7000)
            if (response.isValid) {
                let county = response.county ? response.county.toLowerCase() : ''
                let provider = response.companyId ? response.companyId.toLowerCase() : ''
                window.parent.location.replace('https://quotes.solarfinder.ie/solar-recommendation/' + county + '?p=' + provider)
            }
            else {
                if (response.invalidReason === 'Profanity' || response.invalidReason === 'Fake Name' || response.invalidReason === 'Famous Name') {
                    //let resp = await api.sendTrollDetails(details.firstName, details.lastName, details.email, details.phone, response.invalidReason)
                    //console.log('response: ', resp)
                    window.parent.location.replace('https://youtu.be/GM-e46xdcUo?t=28')
                }
                else {
                    setInvalid(true)
                    if (response.invalidReason === 'Phone number prefix' || response.invalidReason === 'Phone number repeat' || response.invalidReason === 'Phone number sequential') {
                        setInvalidReason('Phone')
                    }
                    else if (response.invalidReason.includes('Duplicate in last')) {
                        setInvalidReason('Duplicate')
                    }
                    setLoading(false)
                }
            }
        } catch(e) {
            console.log('Error submitting form: ', e)
        }
    }

    function checkEircode() {
        setButtonLoading(true)
        let county = null
        let prefix = eircode.trim().substring(0, 3).toUpperCase();
        console.log('prefix: ', prefix);
        if (eircodesToCounty.hasOwnProperty(prefix)) {
            county = eircodesToCounty[prefix];
        }
        if (county && county.length) {
            updateStage(stage + 1) 
            setEircodeError(false)
        }
        else {
            setEircodeError(true)
        }
        setButtonLoading(false)
    }

    // async function sendSmsConfirmation() {
    //     setButtonLoading(true)
    //     try {
    //         let status = await api.sendSmsConfirmation(phone)
    //         if (status === 'pending') {
    //             updateStage(stage + 1)
    //             setPhoneError(false)
    //         }
    //         else {
    //             setPhoneError(true)
    //         }
    //         setButtonLoading(false)
    //     } catch(e) {
    //         console.log('Error sending confirmation: ', e)
    //         setPhoneError(true)
    //         setButtonLoading(false)
    //     }
    // }

    // async function checkConfirmationCode() {
    //     setButtonLoading(true)
    //     try {
    //         let status = await api.checkConfirmationCode(phone, confirmationCode)
    //         if (status === 'approved') {
    //             updateStage(stage + 1)
    //             setCodeError(false)
    //         }
    //         else {
    //             setCodeError(true)
    //         }
    //         setButtonLoading(false)
    //     } catch(e) {
    //         console.log('Error confirming phone: ', e)
    //         setCodeError(true)
    //         setButtonLoading(false)
    //     }
    // }

    function checkEmail() {
        setButtonLoading(true)
        let trimmed = email.trim();
        console.log('trimmed email: ', trimmed);
        if (validEmail.test(trimmed)) {
            updateStage(stage + 1)
            setEmailError(false)
        }
        else {
            setEmailError(true)
        }
        setButtonLoading(false)
    }

    function nextStage() {
        if (stage === 3) {
            checkEircode()
        }
        else if (stage === 5) {
            checkEmail()
        }
        // else if (stage === 11) {
        //     sendSmsConfirmation()
        // }
        // else if (stage === 12) {
        //     checkConfirmationCode()
        // }
        else {
            setStage(stage + 1)
        }
    }

    function updateStage(newStage) {
        setStage(newStage)
        //dcForm.current.scrollIntoView()
    }

    function checkNextDisabled() {
        if (buttonLoading) {
            return true
        }
        else {
            if (stage === 0) {
                return !mostImportant
            }
            // if (stage === 1) {
            //     return !howMuch
            // }
            if (stage === 1) {
                return !electricBills
            }
            if (stage === 2) {
                return !timeline
            }
            // if (stage === 4) {
            //     return !diverterOrBattery
            // }
            // if (stage === 5) {
            //     return !stories
            // }
            // if (stage === 6) {
            //     return !roofType
            // }
            // if (stage === 7) {
            //     return !hasGrantInterest
            // }
            if (stage === 3) {
                return !eircode
            }
            if (stage === 4) {
                return !firstName || !lastName
            }
            if (stage === 5) {
                return !email
            }
            if (stage === 6) {
                return !phone
            }
            // if (stage === 12) {
            //     return !confirmationCode
            // }
            // if (stage === 12) {
            //     return !privacyPolicy
            // }
        }
    }

    return (
        <>
        {loading ? (
            <Grid container justifyContent='flex-start'>
                <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                        <Calculating 
                            redirectUrl={redirectUrl}
                            form='solarfinder'
                            message='Finding the best solar installer in your county...'
                        />
                    </Box>
                </Grid>
            </Grid>
        ) : (
            <>
            {invalid ? (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            <Invalid 
                                reason={invalidReason}
                            />
                        </Box>
                    </Grid>
                </Grid>
            ): (
            <form ref={dcForm} onSubmit={(e) => e.preventDefault()} style={{height: '100vh', padding: '2%'}} className='solarfinder'>
                <Grid container justifyContent='center' className='progress-bar-container scroll-container'>
                    <Grid xs={11}>
                        <ProgressBar animated variant='warning' now={((stage + 1) / 6) * 100} />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' className='question-container scroll-container'>
                    <Grid xs={10} className='question'>
                        {stage === 0 &&
                            <MostImportant 
                                answer={mostImportant}
                                updateAnswer={(mostImportant) => setMostImportant(mostImportant)}
                                nextStage={() => nextStage()}
                                site='solarfinder'
                            />
                        }
                        {stage === 1 &&
                            <ElectricBills 
                                answer={electricBills}
                                updateAnswer={(electricBills) => setElectricBills(electricBills)}
                                nextStage={() => nextStage()}
                                site='solarfinder'
                            />
                        }
                        {/*stage === 2 &&
                            <HowMuch 
                                answer={howMuch}
                                updateAnswer={(howMuch) => setHowMuch(howMuch)}
                                nextStage={() => nextStage()}
                                electricBills={electricBills}
                                site='solarfinder'
                            />
                        */}
                        {stage === 2 &&
                            <Timeline 
                                answer={timeline}
                                updateAnswer={(timeline) => setTimeline(timeline)}
                                nextStage={() => nextStage()}
                                site='solarfinder'
                            />
                        }
                        {/*stage === 4 &&
                            <DiverterOrBattery 
                                answer={diverterOrBattery}
                                updateAnswer={(diverterOrBattery) => setDiverterOrBattery(diverterOrBattery)}
                                nextStage={() => nextStage()}
                                site='solarfinder'
                            />
                        */}
                        {/*stage === 5 &&
                            <Stories 
                                answer={stories}
                                updateAnswer={(stories) => setStories(stories)}
                                nextStage={() => nextStage()}
                                site='solarfinder'
                            />
                        */}
                        {/*stage === 6 &&
                            <RoofType 
                                answer={roofType}
                                updateAnswer={(roofType) => setRoofType(roofType)}
                                nextStage={() => nextStage()}
                                site='solarfinder'
                            />
                        */}
                        {/*stage === 7 &&
                            <GrantInterest 
                                answer={hasGrantInterest}
                                updateAnswer={(hasGrantInterest) => setHasGrantInterest(hasGrantInterest)}
                                nextStage={() => nextStage()}
                                site='solarfinder'
                            />
                        */}
                        {stage === 3 &&
                            <Eircode 
                                eircode={eircode}
                                setEircode={(eircode) => setEircode(eircode)}
                                nextStage={() => nextStage()}
                                site='solarfinder'
                                hasError={eircodeError}
                            />
                        }
                        {stage === 4 &&
                            <Name 
                                firstName={firstName}
                                lastName={lastName}
                                setFirstName={(firstName) => setFirstName(firstName)}
                                setLastName={(lastName) => setLastName(lastName)}
                                nextStage={() => nextStage()}
                                site='solarfinder'
                            />
                        }
                        {stage === 5 &&
                            <Email 
                                email={email}
                                setEmail={(email) => setEmail(email)}
                                nextStage={() => nextStage()}
                                site='solarfinder'
                                hasError={emailError}
                            />
                        }
                        {stage === 6 &&
                            <Phone 
                                phone={phone}
                                setPhone={(phone) => setPhone(phone)}
                                nextStage={() => nextStage()}
                                site='solarfinder'
                                hasError={phoneError}
                                finalStep
                            />
                        }
                        {/*stage === 12 &&
                            <ConfirmPhone 
                                phone={phone}
                                confirmationCode={confirmationCode}
                                setConfirmationCode={(confirmationCode) => setConfirmationCode(confirmationCode)}
                                nextStage={() => nextStage()}
                                form='solarfinder'
                                hasError={codeError}
                            />
                        */}
                        {/*stage === 12 &&
                            <PrivacyPolicy 
                                privacyPolicy={privacyPolicy}
                                setPrivacyPolicy={(privacyPolicy) => setPrivacyPolicy(privacyPolicy)}
                                agree={() => submitForm()}
                                form='solarfinder'
                            />
                        */}
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' className='bottom-container scroll-container'>
                    <Grid xs={11} display='flex' justifyContent={stage > 0 ? 'space-between' : 'flex-end'} alignItems='center' className='buttons'>
                        {stage > 0 && 
                            <Button color='warning' variant='outlined' size='large' onClick={() => updateStage(stage - 1)}>Back</Button>
                        }
                        {stage < 6 && 
                            <Button disabled={checkNextDisabled()} color='warning' variant='contained' size='large' onClick={() => nextStage()}>
                                {buttonLoading ? (
                                    <Loader height={10} color='white' />
                                ) : (
                                    <>Next</>
                                )}
                            </Button>
                        }
                        {stage === 6 &&
                            <Button color='warning' variant='contained' size='large' onClick={() => submitForm()}>Agree & Submit</Button>
                        }
                    </Grid>
                </Grid>
            </form>
            )}
            </>
        )}
        </>
    )
}